<template>
  <section class="page-cultural">
    <div class="banner">
      <p class="hd">JWP CULTURAL TOURISM</p>
      <p class="title">
        <span>Cultural Tourism</span>
      </p>
      <p class="desc">
        <span
          >Cooperating with world renowned five-star branded hotels Developing
          strategically located tourism-focused real estate for Southeast
          Asia</span
        >
      </p>
    </div>
    <div class="standard">
      <p class="title">
        <span class="van-hairline--bottom">Comprehensive System</span>
      </p>
      <ul class="boxs">
        <li class="box">
          <img
            src="//static.hoolihome.com/global-jwp/assets/m/landmark/cultural-tourism/icon-1.png"
          />
          <span
            >Market <br />
            Research</span
          >
        </li>
        <li class="box">
          <img
            src="//static.hoolihome.com/global-jwp/assets/m/landmark/cultural-tourism/icon-2.png"
          />
          <span
            >Project<br />
            Evaluation</span
          >
        </li>
        <li class="box">
          <img
            src="//static.hoolihome.com/global-jwp/assets/m/landmark/cultural-tourism/icon-3.png"
          />
          <span>Financing</span>
        </li>
        <li class="box">
          <img
            src="//static.hoolihome.com/global-jwp/assets/m/landmark/cultural-tourism/icon-4.png"
          />
          <span
            >Land<br />
            Acquisition</span
          >
        </li>
        <li class="box">
          <img
            src="//static.hoolihome.com/global-jwp/assets/m/landmark/cultural-tourism/icon-5.png"
          />
          <span
            >Project<br />
            Commencement</span
          >
        </li>
        <li class="box">
          <img
            src="//static.hoolihome.com/global-jwp/assets/m/landmark/cultural-tourism/icon-6.png"
          />
          <span>Construction<br />Management</span>
        </li>
        <li class="box">
          <img
            src="//static.hoolihome.com/global-jwp/assets/m/landmark/cultural-tourism/icon-7.png"
          />
          <span
            >Completion <br />
            & Delivery</span
          >
        </li>
        <li class="box">
          <img
            src="//static.hoolihome.com/global-jwp/assets/m/landmark/cultural-tourism/icon-8.png"
          />
          <span
            >After-Sales <br />
            Services</span
          >
        </li>
      </ul>
    </div>
    <div class="country-projects" v-if="projects.length > 0">
      <div class="hd">
        <img
          src="//static.hoolihome.com/global-jwp/assets/m/landmark/land/th-flag.png"
        />
        <span class="en">THAILAND</span>
      </div>
      <project-list :projects="projects"></project-list>
    </div>
  </section>
</template>

<script>
import ProjectList from "@/components/project/ProjectList";

export default {
  components: { ProjectList },
  data() {
    return {
      projects: []
    };
  },
  beforeCreate() {
    document.title = "Cultural Tourism";
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      const params = {};
      params.url = this.$apis.jwpList;
      params.data = {
        type: 1,
        page: 1,
        limit: 3,
        belong: 1
      };
      this.$net.req(params).then(res => {
        if (res) {
          const { data } = res;
          if (data && Array.isArray(data.list)) {
            this.projects = data.list;
          }
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.page-cultural {
  .banner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 360px;
    background: url("//static.hoolihome.com/global-jwp/assets/m/landmark/cultural-tourism/banner.jpg")
      no-repeat center center / cover;
    .hd {
      padding-bottom: 83px;
      font-size: 22px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 30px;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    }
    .title {
      padding-bottom: 16px;
      span {
        padding-bottom: 4px;
        font-size: 20px;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        border-bottom: 2px rgba(255, 255, 255, 0.5) solid;
      }
    }
    .desc {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 22px 39px;
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      text-align: center;
      span {
        display: inline-block;
        line-height: 17px;
      }
    }
  }
  .standard {
    padding: 24px 22px 20px;
    text-align: center;
    .title {
      padding-bottom: 32px;
      text-align: center;
      span {
        padding-bottom: 4px;
        font-size: 20px;
        font-weight: 300;
        color: rgba(1, 61, 125, 1);
        &::after {
          border-color: rgba(1, 61, 125, 1);
        }
      }
    }
    .boxs {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 68px;
        padding-bottom: 20px;
        img {
          width: 28px;
        }
        span {
          margin-top: 4px;
          font-size: 12px;
          font-weight: 400;
          color: rgba(38, 38, 38, 1);
          line-height: 17px;
        }
      }
    }
  }
  .country-projects {
    padding-bottom: 40px;
    .hd {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 22px;
      img {
        width: 20px;
      }
      .en {
        margin: 0 8px 0 12px;
        font-size: 18px;
        font-weight: 400;
        color: rgba(38, 38, 38, 1);
        line-height: 25px;
      }
    }
  }
}
</style>
